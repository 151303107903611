import { errorTip, successTip } from '@/utils/Tip'
import LoginRequest from '@/request/System/LoginRequest'
import MenuRequest from '@/request/System/MenuRequest'

const loginRequest = new LoginRequest()
const menuRequest = new MenuRequest()
export default {
  data () {
    return {
      form: {
        /* user_name: 'admin',
        password: '111111' */
        user_name: '',
        password: ''
      },
      rules: {
        user_name: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入用户名'
          }
        ],
        password: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }]
      }
    }
  },
  // 初始化函数
  methods: {
    // 登录点击
    LoginClick: async function () {
      // 登录操作
      const result = await loginRequest.LoginPost(this.form)
      if (result.code !== 200) errorTip(this, result.message)

      sessionStorage.setItem('token', result.data)
      successTip(this, '登录成功')

      // 获取角色菜单
      const menu = await menuRequest.GetUserMenu({
        page: 1,
        limit: 100000
      })
      if (menu.code !== 200) errorTip(this, menu.message)
      sessionStorage.setItem('menu', JSON.stringify(menu.data))

      // 跳转页面
      setTimeout(() => {
        this.$router.push('/SystemHome')
      }, 2000)
    }
  }
}
