import request from '../../utils/request'

export default class MenuRequest {
  // 页面初始化
  async InitPage () {
    return await request.post('/System/Login/InitPage', null)
  }

  // 登录操作
  async LoginPost (data) {
    return await request.post('/System/Login/LoginSubmit', data)
  }
}
